import { getUrl } from '@/lib/utils'
import Button from '../Button/Button'
import ClausePreview from '../ClauseHoverPreview'
import { type RechtssatzNorm } from '../Search/Result'

export default function DecisionButton ({ normId, abbreviation, type, norm: fullNorm, enumeration, abbrNr, openInNewTab }: RechtssatzNorm & { openInNewTab?: boolean }) {
  const content = (
    <span
      style={{
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {fullNorm || `${abbreviation ?? ''} ${(enumeration !== '§ 0' ? enumeration : undefined) ?? ''}`}
    </span>
  )
  if (!normId || !enumeration || !type) {
    return (
      <Button size='small' variant='accent' key={fullNorm} disabled>
        {content}
      </Button>
    )
  }

  return (
    <ClausePreview lawId={normId} clauseId={enumeration}>
      <Button
        size='small'
        variant='accent'
        key={fullNorm}
        href={getUrl(type, { norm: abbreviation ?? normId, abbrNr, enumeration })}
        target={openInNewTab ? '_blank' : undefined}
      >
        {content}
      </Button>
    </ClausePreview>
  )
}
