'use client'

import Button from '@/components/Button/Button'
import Heading from '@/components/Heading/Heading'
import { useIsTop } from '@/components/NavBar/hooks'
import Search from '@/components/Search/Search'
import Section from '@/components/Section'
import Text from '@/components/Text/Text'
import { deterministicUrlify, formatNumber, getUrl } from '@/lib/utils'
import { Columns, Grid, Row, Stack } from '@christiankaindl/lyts'
import { Tab, TabList, TabPanel, useTabStore } from '@ariakit/react'
import { motion, useSpring, useTransform } from 'framer-motion'
import { ChevronRightIcon, GitCompareIcon, ScaleIcon, SearchIcon } from 'lucide-react'
import Link from 'next/link'
import { useTypewriter } from 'react-simple-typewriter'
import { pathMappings } from '@/lib/paths'
import { iconMappings } from '@/lib/iconMappings'
import Reel from '@/components/Reel/Reel'
import { card } from '@/styles/theme.css'
import * as styles from './index.css'
import { Suspense } from 'react'
import type { SearchResponse, SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents'
import type { IDocument } from '@/lib/search.server'

export function SearchWithTypewriter () {
  const isTop = useIsTop()
  const [text] = useTypewriter({
    words: ['Gesetzen', 'Entscheidungen', 'Verordnungen', 'Rechtssätzen', 'Verträgen', 'OGH Urteilen', 'Kundmachungen'],
    loop: true,
    deleteSpeed: 35,
    typeSpeed: 125,

  })

  return (
    <Suspense>
      <Search placeholder={`Suche nach ${text}`} id='home-search' enableShortcut={isTop} autoFocus center inputProps={{ style: { paddingRight: 95 } }} />
    </Suspense>
  )
}

export function FocusSearchButton () {
  function focusSearch (): void {
    window.scrollTo({ top: 0 })
    // @ts-expect-error Use the following global function, because calling .focus() or .click() doesn't trigger the suggestion popover
    window._workaroundOpenSearch()
  }

  return (
    <Button onClick={focusSearch} size='large' style={{ width: '100%' }}>
      <Row gap={0.5}><SearchIcon size={20} /> Durchsuchen</Row>
    </Button>
  )
}

export function StatsSection () {
  // const spring = useSpring(0, { bounce: 0, duration: 4000, restDelta: 0.0001 })
  const spring = useSpring(0, { damping: 20, mass: 0.1, restDelta: 0.0001 })
  const paras = useTransform(spring, (value) => { return formatNumber(Math.ceil(148763 * value)) })
  const entscheidungen = useTransform(spring, (value) => { return formatNumber(Math.ceil(250643 * value)) })
  const connections = useTransform(spring, (value) => { return formatNumber(Math.ceil(1198218 * value)) })

  return (
    <Section style={{ textAlign: 'center' }} stackProps={{ xAlign: 'center' }}>
      <motion.div
        onViewportEnter={() => {
          spring.set(1)
        }}
      />
      <Columns style={{ width: '100%' }} collapseAt='54em' gap={3}>
        <Stack xAlign='center'>
          <div
            style={{
              height: 48,
              width: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(228, 177, 95, 0.2)',
              borderRadius: 99,
              marginLeft: -6,
              marginTop: -6,
              marginBottom: -6,
              color: '#A48757',
              flexShrink: 0,
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            §
          </div>
          <Heading h={1}>
            <motion.span>{paras}</motion.span>
            <br />
            <span style={{ opacity: 0.35 }}>Paragrafen</span>
          </Heading>
        </Stack>
        <Stack xAlign='center'>
          <div
            style={{
              height: 48,
              width: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(228, 177, 95, 0.2)',
              borderRadius: 99,
              marginLeft: -6,
              marginTop: -6,
              marginBottom: -6,
              color: '#A48757',
              flexShrink: 0,
              fontWeight: 700,
            }}
          >
            <ScaleIcon size={24} />
          </div>
          <Heading h={1}>
            <motion.span>{entscheidungen}</motion.span>
            <br />
            <span style={{ opacity: 0.35 }}>Entscheidungen<br/>&amp; Rechtssätze</span>
          </Heading>
        </Stack>
        <Stack xAlign='center'>
          <div
            style={{
              height: 48,
              width: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(228, 177, 95, 0.2)',
              borderRadius: 99,
              marginLeft: -6,
              marginTop: -6,
              marginBottom: -6,
              color: '#A48757',
              flexShrink: 0,
              fontWeight: 700,
            }}
          >
            <GitCompareIcon size={24} />
          </div>
          <Heading h={1}>
            <motion.span>{connections}</motion.span>
            <br />
            <span style={{ opacity: 0.35 }}>Verknüpfungen</span>
          </Heading>
        </Stack>
      </Columns>
      <Text color='secondary'>
        GesetzeFinden.at verknüpft das österreichische Recht miteinander, und macht es einfach durchsuchbar. Das Angebot an Inhalten wird stetig erweitert und umfasst aktuell alle <Link href='/bundesrecht'>Bundesnormen</Link> (Bundesverfassungsgesetze, Bundesgesetze, Verordnungen, etc.) sowie <Link href='/judikatur'>Entscheidungen &amp; Rechtssätze</Link> von den Gerichten der Justiz (OGH, OLG, LG, BG, etc.).
      </Text>
      <Button href='/datenquellen'>
        Welche Datenquellen verwenden wir?
        <ChevronRightIcon size={16} />
      </Button>
    </Section>
  )
}

export function ReelSection ({ bundesrecht, judikatur }: { bundesrecht: SearchResponse<IDocument>, judikatur: SearchResponseFacetCountSchema<IDocument>['counts'] }) {
  const tabs = useTabStore({ defaultSelectedId: 'bundesrecht' })
  const selectedId = tabs.useState('selectedId')
  const items = tabs.useState('items')

  function isTab (id) {
    if (id !== selectedId) {
      return {
        style: {
          fontSize: '1.35em',
          fontFamily: 'var(--noto-font)',
          fontWeight: 'bold',
          color: 'rgb(0 0 0 / 0.6)',
        },
        variant: 'flat',
      }
    }
    return {
      style: {
        fontSize: '1.35em',
        fontFamily: 'var(--noto-font)',
        fontWeight: 'bold',
        color: 'rgb(0 0 0 / 0.6)',
      },
    }
  }

  return (
    <Section gap={1} style={{ minHeight: 'unset' }} clamp='calc(1150px + 3em)'>
      <TabList store={tabs} render={<Row gap={0.5} />}>
        <Tab store={tabs} render={<Button color='secondary' size='large' />} id='bundesrecht' {...isTab(items[0]?.id)}>Bundesrecht</Tab>
        <Tab store={tabs} render={<Button color='secondary' size='large' />} id='judikatur' {...isTab(items[1]?.id)}>Judikatur</Tab>
      </TabList>

      {/* Bundesrecht Reel */}
      {/* @ts-expect-error Reel type must have children */}
      <TabPanel store={tabs} tabId='bundesrecht' render={<Reel />}>
        <Stack className={styles.cardItem} style={{ maxWidth: '19em', padding: 0, scrollSnapAlign: 'center' }} xAlign='start'>
          <Text color='secondary'>
            {pathMappings.bundesrecht.description}
          </Text>
        </Stack>
        <Grid style={{ gridTemplateRows: 'auto auto auto', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(4, 20em)' }}>
          {Object.entries(pathMappings.bundesrecht.paths).map(([key, category], index) => {
            const Icon = iconMappings[key]
            return (
              <Row key={key} asChild className={styles.cardItem} style={{ width: '100%', paddingBottom: 18 }}>
                <Link className={card} href={getUrl(category.type)} key={category.name}>
                  <Icon color='#A48757' size={20} width={20} />
                  <Heading h={5} maxLines={1} style={{ fontFamily: 'var(--noto-font)' }}>
                    {category.name}
                  </Heading>
                </Link>
              </Row>
            )
          })}
        </Grid>
        <div style={{ width: '3em', flexShrink: 0 }} />
        {Object.entries(pathMappings.bundesrecht.paths).map(function reelList ([key, category], index) {
          const algoliaCat = bundesrecht.grouped_hits?.find(({ group_key: groupKey }) => groupKey[0] === category.type)
          const Icon = iconMappings[key]
          if (!algoliaCat) return null

          return (
            <Stack key={category.name} gap={0.5}>
              <div className={`${card} ${styles.cardItem}`}>
                <Stack gap='1px'>
                  <Row asChild>
                    <Link href={getUrl(category.type)} style={{ marginBottom: 6 }}>
                      <Icon color='#A48757' size={20} width={20} />
                      <Heading h={4} maxLines={1} style={{ fontFamily: 'var(--noto-font)' }}>
                        {category.name}
                      </Heading>
                    </Link>
                  </Row>
                  {algoliaCat?.hits.map(function normList ({ document: item }) {
                    if (!item.lawType) return null

                    return (
                      <Button
                        size='small'
                        variant='flat'
                        key={item.normId}
                        title={item.title}
                        style={{ width: 'calc(100% + 18px)', marginLeft: -9, marginRight: -9, fontWeight: 400 }}
                        href={getUrl(item.lawType, { norm: item.abbreviation ?? item.normId })}
                      >
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>
                          {item.title}
                        </span>
                      </Button>
                    )
                  })}
                </Stack>
              </div>

              {algoliaCat.found && (
                <Button variant='flat' size='tiny' href={getUrl(category.type)} style={{ marginLeft: 15, marginBottom: -15 }}>
                  <span>Alle {formatNumber(algoliaCat.found)} anzeigen</span>
                  <ChevronRightIcon size={16} />
                </Button>
              )}
            </Stack>
          )
        })}
      </TabPanel>

      {/* Judikatur Reel */}
      {/* @ts-expect-error Reel type must have children */}
      <TabPanel store={tabs} tabId='judikatur' render={<Reel />}>
        <Stack className={styles.cardItem} style={{ maxWidth: '19em', padding: 0, scrollSnapAlign: 'center' }} xAlign='start'>
          <Text color='secondary'>
            {pathMappings.judikatur.description}
          </Text>
        </Stack>
        <Grid style={{ gridTemplateRows: 'auto auto auto', gridAutoFlow: 'column', gridTemplateColumns: `repeat(${Math.ceil(judikatur.length / 3)}, 20em)` }}>
          {judikatur.map(({ count, value }) => {
            return (
              <Row key={value} asChild className={styles.cardItem} style={{ width: '100%', paddingBottom: 18 }}>
                <Link className={card} href={`/judikatur/${deterministicUrlify(value)}`}>
                  <ScaleIcon size={20} color='#A48757' />
                  <Heading h={5} maxLines={1} style={{ fontFamily: 'var(--noto-font)' }}>
                    {value}
                  </Heading>
                  <Text color='secondary' size='small' style={{ marginLeft: 'auto' }}>
                    {formatNumber(count)}
                  </Text>
                </Link>
              </Row>
            )
          })}
        </Grid>
      </TabPanel>
    </Section>
  )
}
