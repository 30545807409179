import LawIcon from '@/public/assets/law.icon.svg'
import { FeatherIcon, MegaphoneIcon, ScaleIcon, ScrollIcon } from 'lucide-react'

export const iconMappings = {
  bundesgesetze: LawIcon,
  bundesverfassungsgesetze: LawIcon,
  verordnungen: LawIcon,
  vertraege: FeatherIcon,
  kundmachungen: MegaphoneIcon,
  vereinbarungen: ScrollIcon,
  geschaeftsordnungen: ScrollIcon,
  entschliessungen: ScrollIcon,
  bekanntmachungen: ScrollIcon,
  verfuegungen: ScrollIcon,
  justiz: ScaleIcon,
}
